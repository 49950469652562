import './HostedPagePayment.scss';

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { HiButton } from '@hipay/design-system/components';
import { Launch } from '@mui/icons-material';

import { updateHPageIFrameConfig } from '../../actions/sdkConfigActions';
import {
  getPaymentModeDraftConfig,
  getBackendSdkConfig,
  isHPageIFrame,
  getCurrency,
  getThemeCode,
  getCarouselDraftConfig,
  getPaymentDraftConfig
} from '../../selectors/configSelectors';
import HostedPageStep from '../../components/HostedPageStep/HostedPageStep';

const HostedPagePayment = (props) => {
  const console_url_production = 'https://console.hipay.com/transactions/';
  const console_url_stage = 'https://stage-console.hipay.com/transactions/';

  let navigate = useNavigate();

  const orderData = useMemo(() => {
    return {
      amount: props.client.environment === 'production' ? 1.0 : 100,
      shipping: props.client.environment === 'production' ? 0.3 : 30,
      currency: props.currency
    };
  }, [props.client.environment, props.currency]);

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [paymentData, setPaymentData] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [hPageErrorsStep2, setHPageErrorsStep2] = useState(null);
  const [hPageErrorsStep3, setHPageErrorsStep3] = useState(null);
  const [windowRef, setWindowRef] = useState(null);
  const [canCloseModal, setCanCloseModal] = useState(false);
  const [displaySteps, setDisplaySteps] = useState(false);

  const receiver = new BroadcastChannel('hpage_payment');

  receiver.onmessage = (messageEvent) => {
    if (messageEvent.origin !== process.env.REACT_APP_FRONTEND_URL) return;
    if (messageEvent.data.state === 'complete') {
      setTransactionData(messageEvent.data.params);
    }
  };

  receiver.onmessageerror = (err) => {
    console.error(err);
  };

  const getParams = useCallback(() => {
    return {
      reference: searchParams.get('reference'),
      ipAddress: searchParams.get('ip'),
      avsCheck: searchParams.get('avscheck'),
      eci3ds: searchParams.get('eci3ds'),
      paymentMeans: searchParams.get('pp'),
      payment_method: {
        token: searchParams.get('cardtoken'),
        brand: searchParams.get('cardbrand'),
        pan: searchParams.get('cardpan'),
        expiry: searchParams.get('cardexpiry'),
        country: searchParams.get('cardcountry')
      },
      fraud_screening: {
        scoring: searchParams.get('score'),
        fraud: searchParams.get('fraud'),
        review: searchParams.get('review')
      },
      order: {
        orderId: searchParams.get('orderid'),
        amount: searchParams.get('amount'),
        currency: searchParams.get('currency')
      },
      mid: paymentData?.mid,
      date_authorized: searchParams.get('authorized'),
      status: searchParams.get('status'),
      state: searchParams.get('state'),
      email: searchParams.get('email'),
      cid: searchParams.get('cid'),
      test: searchParams.get('test'),
      lang: searchParams.get('lang'),
      cvcCheck: searchParams.get('cvccheck'),
      veres: searchParams.get('veres'),
      pares: searchParams.get('pares')
    };
  }, [paymentData, searchParams]);

  const processOrder = useCallback(() => {
    return axios.post(process.env.REACT_APP_BACKEND_URL, {
      hostedPage: true,
      referrer: {
        checkoutUrl: `${process.env.REACT_APP_FRONTEND_URL}/hpage_payment`
      },
      client: {
        ...props.client
      },
      payment_product_list: {
        ...props.carousel.payment_product
      },
      order: orderData,
      iFrame: props.iframe,
      themeCode: props.themeCode,
      isApplePay: false,
      multi_use: props.config.card.multi_use,
      display_cancel_button: props.config.display_cancel_button
    });
  }, [
    orderData,
    props.client,
    props.iframe,
    props.themeCode,
    props.carousel,
    props.config
  ]);

  // When button clicked, go to Console transaction notice
  const handleGoToConsole = () => {
    const consoleUrl =
      props.client.environment === 'production'
        ? console_url_production
        : console_url_stage;
    const transactionId = transactionData.reference;

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${consoleUrl + transactionId}`, '_blank');
  };

  // When restart clicked (or Home), reset hostedInstance & go to home page
  const handleRestart = () => {
    // Redirect to home
    navigate('/');
  };

  useEffect(() => {
    if (location.state) {
      setPaymentData(location.state.finalPaymentData);
      setTransactionData(location.state.finalTransactionData);
      props.updateHPageIFrameConfig('iframe', true);
      setDisplaySteps(true);
    } else {
      setContainerWidth(document.getElementById('page-content').offsetWidth); // Set the size of the iframe
      if (searchParams.size === 0) {
        processOrder()
          .then((response) => {
            setPaymentData(response.data);
            if (!props.iframe) {
              setDisplaySteps(true);
              var features =
                'resizable=yes;status=no;scroll=no;help=no;center=yes;width=460;height=140;menubar=no;directories=no;location=no;modal=yes';
              setWindowRef(
                window.open(
                  response.data.forward_url,
                  '_blank',
                  features,
                  false
                )
              );
            } else {
              props.setPaymentData(response.data);
            }
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setHPageErrorsStep2(err.response?.data);
            }
          });
      } else if (searchParams.size > 0) {
        let params = getParams();
        if (
          searchParams.get('state') === 'completed' ||
          searchParams.get('state') === 'pending' ||
          searchParams.get('state') === 'pending_reference'
        ) {
          if (Boolean(searchParams.get('iframe'))) {
            const broadcasterIFrame = new BroadcastChannel('iframe');
            broadcasterIFrame.postMessage({
              params: params,
              state: 'complete'
            });
          } else {
            const broadcaster = new BroadcastChannel('hpage_payment');
            broadcaster.postMessage({ params: params, state: 'complete' });
            setCanCloseModal(true);
            window.open('', '_self').close();
          }
        } else if (searchParams.get('err')) {
          if (Boolean(searchParams.get('iframe'))) {
            const broadcasterIFrame = new BroadcastChannel('iframe');
            broadcasterIFrame.postMessage({ params: null, state: 'cancel' });
          } else {
            setCanCloseModal(true);
            window.open('', '_self').close();
          }
        } else {
          setHPageErrorsStep3(params);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set an error when the payment window is closed
    if (windowRef !== null) {
      var timer = setInterval(function () {
        if (windowRef.closed) {
          clearInterval(timer);
          !canCloseModal &&
            setHPageErrorsStep3(
              props.intl.formatMessage({ id: 'hpage-payment-window-closed' })
            );
        }
      }, 1000);
    }
  }, [windowRef, props.intl, canCloseModal]);

  return (
    <div id="page-content" className="page-content">
      {displaySteps ? (
        <>
          <div className="hpage-payment-state">
            <HostedPageStep
              title="hpage-payment-step-1-title"
              data={orderData}
              error={hPageErrorsStep2}
              help="hpage-payment-step-1-help"
              waitingText="hpage-payment-step-1-waiting"
              errorText="hpage-payment-step-1-error"
            />
            {orderData && (
              <HostedPageStep
                title="hpage-payment-step-2-title"
                data={paymentData?.order}
                error={hPageErrorsStep2}
                help="hpage-payment-step-2-help"
                waitingText="hpage-payment-step-2-waiting"
                errorText="hpage-payment-step-2-error"
              />
            )}
            {paymentData && (
              <HostedPageStep
                title="hpage-payment-step-3-title"
                data={transactionData}
                error={hPageErrorsStep3}
                help="hpage-payment-step-3-help"
                waitingText="hpage-payment-step-3-waiting"
                errorText="hpage-payment-step-3-error"
              />
            )}
          </div>
          <div className="actions-hpage">
            {(transactionData || hPageErrorsStep2 || hPageErrorsStep3) && (
              <HiButton
                className="action-button"
                variant={
                  transactionData || hPageErrorsStep2 || hPageErrorsStep3
                    ? 'outlined'
                    : 'text'
                }
                onClick={handleRestart}
              >
                <FormattedMessage id={'restart'} />
              </HiButton>
            )}
            {transactionData && (
              <HiButton
                className="action-button"
                color="primary"
                variant="contained"
                onClick={handleGoToConsole}
                endIcon={<Launch />}
              >
                <FormattedMessage id={'view-in-console'} />
              </HiButton>
            )}
          </div>
        </>
      ) : (
        props.iframe && (
          <iframe
            src={paymentData?.forward_url}
            title={'Hosted Page'}
            width={containerWidth - 64}
            height={640}
            sandbox="allow-scripts allow-same-origin"
            frameBorder="0"
            referrerPolicy="same-origin"
          />
        )
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    payment_mode: getPaymentModeDraftConfig(state),
    client: getBackendSdkConfig(state),
    currency: getCurrency(state),
    iframe: isHPageIFrame(state),
    themeCode: getThemeCode(state),
    config: getPaymentDraftConfig(state),
    carousel: getCarouselDraftConfig(state)
  };
};

export default connect(mapStateToProps, {
  updateHPageIFrameConfig
})(injectIntl(HostedPagePayment));
