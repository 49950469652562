import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { FormGroup } from '@mui/material';
import {
  getPaymentDraftConfig,
  isMethodEnabled
} from '../../selectors/configSelectors';
import {
  updatePaymentProductConfig,
  updateDisplayCancelButton
} from '../../actions/sdkConfigActions';

import { HiSwitch } from '@hipay/design-system/components';

function ConfigHPagePayment(props) {
  let handleSwitchMultiUseChange = (field, name) => (event) => {
    props.updatePaymentProductConfig('card', field, name, event.target.checked);
  };

  let handleSwitchCancelButtonChange = (name) => (event) => {
    props.updateDisplayCancelButton(name, event.target.checked ? 1 : 0);
  };

  return (
    <>
      {props.cardEnabled && (
        <div className="ConfigHFcard">
          <h2>
            <FormattedMessage id={'card-default-config'} />
          </h2>
          <FormGroup name="groupName">
            <HiSwitch
              id={'global.multi_use'}
              checked={props.config.card.multi_use}
              onChange={handleSwitchMultiUseChange('option', 'multi_use')}
              label={<FormattedMessage id={'multi-use'} />}
            />
          </FormGroup>
        </div>
      )}
      <h2>
        <FormattedMessage id={'hpage-config'} />
      </h2>
      <FormGroup name="groupName">
        <HiSwitch
          id={'global.display_cancel_button'}
          checked={props.config.display_cancel_button === 1}
          onChange={handleSwitchCancelButtonChange('display_cancel_button')}
          label={<FormattedMessage id={'display_cancel_button'} />}
        />
      </FormGroup>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    config: getPaymentDraftConfig(state),
    cardEnabled: isMethodEnabled(state, 'card')
  };
};

export default connect(mapStateToProps, {
  updatePaymentProductConfig,
  updateDisplayCancelButton
})(injectIntl(ConfigHPagePayment));
